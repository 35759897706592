import {AuthService} from 'app/services/auth.service';
import {Component, OnInit} from '@angular/core';
import {LocaleService} from '../../../services/locale.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProfileCompleteComponent} from '../../modal/profile-complete/profile-complete.component';

import {SpecialtyVerifiedComponent} from '../../modal/specialty-verified/specialty-verified.component';
import {CookieService} from 'ngx-cookie-service';
import {CovidModalComponent} from '../../covid-modal/covid-modal.component';

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginError: string;
  env = environment;
  locale: string;
  loginData = {
    username: '',
    password: '',
    platform: '',
    rememberMeChecked: true
  };
  loading = false;
  returnUrl: string;
  token: string;
  environment = environment;

  constructor(
    private localeService: LocaleService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: NgbModal,
    private cookieService: CookieService
  ) {

  };

  ngOnInit() {

    // reset login status
    this.authService.logoutWithoutRedirect();
    this.locale = this.localeService.currentLocale();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    this.route.params.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        setTimeout(() => {
          this.authService.loginAs(this.token).subscribe(logged => {
            this.authService.setSession(logged);
            this.router.navigateByUrl(this.returnUrl + '?connected=1');
          });
          this.authService.loginMainWebsiteToken(this.token).subscribe(() => {
          });
        }, 2000);
      }
    });
  }

  login() {
    if (!this.loading) {
      this.loading = true;
      const loginData = this.loginData;
      this.authService.loginMainWebsite({email: loginData.username, password: loginData.password})
        .subscribe(logged => {
          if (logged.body.freeMonth || logged.body.freeMonthOrthoptist || logged.body.freeMonthOphthalmologist){
            this.modalService.open(CovidModalComponent);
          }
          this.loginRequest(loginData);

        }, err => {
          this.loginRequest(loginData);
        });
    }
  }

  loginRequest(loginData) {
    this.authService.login(loginData).subscribe(
      logged => {
        if (logged.body.oneMonthFree) {
          this.modalService.open(CovidModalComponent);
        }
        this.authService.setSession(logged);
        if (!logged.body.website_ready) {
          window.location.href = this.environment.base_url + '/account/professional-data';
        }
        this.loading = false;
        const user = this.authService.getCurrentUser();
        if (!user.themes || user.themes.length === 0) {
          this.router.navigateByUrl('/account/create/step-2');
        } else if (user.diploma_status === 'premium_not_verified') {
          this.showDiplomaModal();
          this.router.navigateByUrl(this.returnUrl + '?connected=1');
        } else if (!user.address) {
          this.openProfileToComplete();
          this.router.navigateByUrl('/account/professional-data');
        } else {
          this.router.navigateByUrl(this.returnUrl + '?connected=1');
        }
      },
      logErr => {
        if (logErr.error) {
          this.loginError = logErr.error.message;
        }
        this.loading = false;
      });
  }

  openProfileToComplete() {
    const alreadyShow = this.cookieService.get('modal_profile_complete');
    if (!alreadyShow || (alreadyShow && alreadyShow !== 'ok')) {
      const modalRef = this.modalService.open(ProfileCompleteComponent);
      modalRef.componentInstance.title = 'account.Your_profile_is_incomplete';
      modalRef.componentInstance.subtitle = 'account.Please_complete_your_profile';
    }
  }

  showDiplomaModal() {
    const alreadyShow = this.cookieService.get('modal_diploma');
    if (!alreadyShow || (alreadyShow && alreadyShow !== 'ok')) {
      const modalRef = this.modalService.open(SpecialtyVerifiedComponent);
      modalRef.componentInstance.title = 'academy.diploma_required_title';
      modalRef.componentInstance.subtitle = 'academy.diploma_required_text_premium';
      modalRef.componentInstance.button = 'academy.upload_diploma_now';
    }
  }
}
