import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from "../../../environments/environment";
import {User} from "../../models/user.model";
import {ExtendComment} from "../pages/alert-topic/alert-topic.component";
import {AlertService} from "../../services/alert.service";
import {TranslationService} from "../../services/translation.service";
import {DateMomentService} from "../../services/date-moment.service";
import {HistoryService} from "../../services/mobile-services/history.service";
import * as $ from "jquery";
import {FilesService} from "../../services/files.service";


@Component({
  selector: 'app-alert-comment-template',
  templateUrl: './alert-comment-template.component.html',
  styleUrls: ['./alert-comment-template.component.scss'],
  providers: [
    FilesService
  ]
})
export class AlertCommentTemplateComponent implements OnInit {
  @Input() comment: ExtendComment;
  @Input() canReply: boolean;
  @Input() isSubcomment: boolean = false;
  @Input() isWrapup:boolean =false;
  @Output() replyToComment = new EventEmitter<ExtendComment>();
  @Output() canAnswerCaseEditMode = new EventEmitter<boolean>();
  @Output() needReload = new EventEmitter<boolean>();
  @Input() currentUser: User;
  @Input() case: {id: number, open: boolean} = null;
  environment: any = environment;
  locale: any;
  slug: string;
  group: any;
  groups: any;
  anonymous: any = 1;
  imagesToUpload: {text: string, file: File, type: string}[] = [];
  videos_related: any = [];
  error: string | boolean;
  closing: boolean = false;
  acceptedFormat: string ='';
  extension: string = '';
  open: boolean = false;
  newComment: string = null;
  alreadyInEditMode: boolean = false;
  jquerySliderNav: any = null;
  jquerySliderFor: any = null;
  filesToUpload: any[] = [];
  filesUploadFailed: boolean = false;
  fileName: string = '';
  filesError: string = '';
  newCommentFiles = [];
  loadingFiles:boolean = false;

  constructor(private alertService: AlertService,
              public translationService: TranslationService,
              public dateMomentService: DateMomentService,
              public historyService: HistoryService,
              private filesService: FilesService) {
    this.acceptedFormat = filesService.acceptedFormat;
  }
  ngOnInit(): void {
    jQuery(() => {
      setTimeout(() => {
        jQuery('.fancybox').fancybox({
          closeEffect: 'none',
          beforeShow: function () {
            $('.fancybox-overlay').on('contextmenu', function (e) {
              return false;
            });
          }
        });
      }, 100);
    });
  }

  isPicture(picture) {
    return this.filesService.isPicture(picture);
  }

  canEditComment(comment: ExtendComment) {
    comment.edit_comment_mode = true;
    this.canAnswerCaseEditMode.emit(false);
    this.newComment = comment.content;
    this.alreadyInEditMode = true;
  }

  editComment(comment: ExtendComment) {
    comment.edit_comment_mode = false;
    this.alertService.editComment(comment.id, {content: this.newComment}).subscribe(edited => {
      const date = new Date(edited *1000);
      comment.content = this.newComment;
      comment.edited_at = date;
      this.canAnswerCaseEditMode.emit(true);
      this.newComment = null;
      this.alreadyInEditMode = false;
    });
  }

  cancelEditComment(comment) {
    this.newComment = comment.content;
  }

  removeFile(index: number, comment: ExtendComment, isPicture: boolean = true, isDoc: boolean = false) {
    this.alertService.deleteFile(comment.id, {
      alert_id: this.case.id,
      type: isPicture ? 'picture' : 'video',
      file_id:  this.newCommentFiles.find(file => file.id === index).id}
    ).subscribe(res => {
      const fileIndex = this.newCommentFiles.findIndex(file => file.id === index);
      this.newCommentFiles.splice(fileIndex,1);
      const displayFileIndex = (isDoc ? this.displayedDocumentFiles : this.displayedFiles).findIndex(file => file.id === index);
      (isDoc ? this.displayedDocumentFiles : this.displayedFiles).splice(displayFileIndex, 1);
      if (!isDoc) {
        this.jquerySliderNav.slick('slickRemove',displayFileIndex);
        this.jquerySliderFor.slick('slickRemove',displayFileIndex);
        this.jquerySliderFor.slick('refresh');
        this.jquerySliderNav.slick('refresh');
      }
    }, error => {
      this.filesError = 'Back';
      setTimeout(() => this.filesError = '', 5000)
    });
  }

  displayedFiles: Array<any> = [];
  displayedDocumentFiles: Array<any>= [];

  canEditFile(comment) {
    comment.edit_files_mode = true;
    this.canAnswerCaseEditMode.emit(false);
    this.alreadyInEditMode = true;
    this.filesToUpload = [];
    this.newCommentFiles.push(...comment.pictures, ...comment.videos.map(video => {
      return {title: video.file_name, location: video.url, id: video.id, comment_id: video.comment_id}
    }));
    this.newCommentFiles.forEach(file => {
      if (this.filesService.documentFileTypes.find(fileType => file.title.split('.').pop().toLowerCase() !== fileType.extension.toLowerCase()) && !this.filesService.isHeic(file.title)) {
        this.displayedFiles.push(file);
      } else {
        this.displayedDocumentFiles.push(file);
      }
    });
    setTimeout(() => {
      this.jquerySliderNav = $('.pictures-slider-nav');
      this.jquerySliderFor = $('.pictures-slider-for');
      if (this.jquerySliderNav.length > 0 && this.jquerySliderFor.length > 0) {
        this.loadingFiles = false;
        this.jquerySliderNav.not('.slick-initialized').slick({
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: '.pictures-slider-for',
          autoplay: false,
          focusOnSelect: true,
          adaptiveHeight: true,
          dots: false,
          prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
          responsive: [{
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              infinite: false
            }
          }]
        });
        this.jquerySliderFor.not('.slick-initialized').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true,
          adaptiveHeight: true,
          fade: true,
          dots: false,
          asNavFor: '.pictures-slider-nav',
          prevArrow: '',
          nextArrow: ''
        });
      }
    }, 50);
  }

  quitEditMode(comment) {
    comment.edit_comment_mode = false;
    comment.edit_files_mode = false;
    this.canAnswerCaseEditMode.emit(true);
    this.newCommentFiles = [];
    this.filesToUpload = [];
    this.newComment = null;
    this.filesUploadFailed = false;
    this.alreadyInEditMode = false;
    if (this.jquerySliderNav && this.jquerySliderFor) {
      this.jquerySliderFor.slick('unslick');
      this.jquerySliderNav.slick('unslick');
    }
    this.needReload.emit(true);
  }

  uploadFiles(comment: ExtendComment) {
    this.loadingFiles = true;
    const body = new FormData();
    this.filesToUpload.forEach((file, index) =>  {
      body.append('files['+ index +'][content]', file.file, file.text);
      body.append('files[' + index +'][name]', file.text);
      body.append('files[' +index + '][type]', file.type);
    });
    body.append('alert_id', this.case.id.toString());
    this.alertService.addFiles(comment.id, body).subscribe(result => {
      result.forEach(file => {
        this.newCommentFiles.push({
          title: file.name,
          location: file.location,
          id: file.id
        });
      });
      this.filesToUpload = [];
      this.canAnswerCaseEditMode.emit(true);
      this.needReload.emit(true);
    }, error => {
      this.filesError = 'Back';
      this.filesToUpload = [];
      this.loadingFiles = true;
      setTimeout(() => {
        this.quitEditMode(comment);
        this.filesError = "";
      }, 5000);
    });
  }

  removeFromList(index: number, doc: boolean = false) {
    this.filesToUpload.splice(index, 1);
    (doc ? this.displayedDocumentFiles : this.displayedFiles).splice(index, 1);
  }

  commentFilesUpload(event) {
    const returnValue = this.filesService.uploadFiles(event, this.filesToUpload, this.newCommentFiles);
    if (returnValue.error) {
      switch (returnValue.error.name) {
        case 'Size' || 'Error': {
          this.filesError = returnValue.error.name;
          this.fileName = returnValue.error.subTextError;
          break;
        }
        default: {
          this.filesError = returnValue.error.name;
        }
      }
      setTimeout(() => this.filesError = '', 5000);
    } else {
      this.filesToUpload = returnValue.newFilesList;
      this.displayedFiles = [];
      this.displayedDocumentFiles = [];
      returnValue.newFilesList.forEach(file => {
        if (this.filesService.documentFileTypes.indexOf(file.title.split('.').pop()) === -1) {
          this.displayedFiles.push(file);
        } else {
          this.displayedDocumentFiles.push(file);
        }
      });
    }
  }

  isVideo(title) {
    return this.filesService.isVideo(title);
  }

  replyTo(comment) {
    this.replyToComment.emit(comment);
  }

  isHeic(title: string) {
    return this.filesService.isHeic(title);
  }
}
