<h1>{{ 'account.Email_notifications'|translate }}</h1>
<div class="notifications">
	<div class="alert alert-success alert-dismissable" *ngIf="updated">
		<button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="updated = false">×</button>
		{{'account.Update_success_flash'|translate}}
	</div>
	<h6>{{'newsletter.Yes_want_receive'|translate}}</h6>
	<app-loader *ngIf="loading"></app-loader>

	<div *ngIf="!loading">
		<div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.subscribe_academy_web)">
			<input type="checkbox" id="notification-1" [(ngModel)]="newUser.subscribe_academy_web" class="custom-control-input">
			<label class="custom-control-label" for="notification-1">{{'newsletter.Box_academy_web'|translate}}</label>
		</div>
		<div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.subscribe_web)">
			<input type="checkbox" id="notification-2" [(ngModel)]="newUser.subscribe_web" class="custom-control-input">
			<label class="custom-control-label" for="notification-2">{{'newsletter.Box_web'|translate}}</label>
		</div>
		<div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.website_email_notification_message)">
			<input type="checkbox" id="notification-3" [(ngModel)]="newUser.website_email_notification_message" class="custom-control-input">
			<label class="custom-control-label" for="notification-3">{{'newsletter.Box_notification_message'|translate}}</label>
		</div>
		<div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.website_email_notification_connection_request)">
			<input type="checkbox" id="notification-4" [(ngModel)]="newUser.website_email_notification_connection_request" class="custom-control-input">
			<label class="custom-control-label" for="notification-4">{{'newsletter.Box_connection_request'|translate}}</label>
		</div>
		<div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.website_email_notification_alert)">
			<input type="checkbox" id="notification-6" [(ngModel)]="newUser.website_email_notification_alert" class="custom-control-input">
			<label class="custom-control-label" for="notification-6">{{'newsletter.Box_alert'|translate}}</label>
		</div>
		<div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.website_email_notification_video_comment)">
			<input type="checkbox" id="notification-7" [(ngModel)]="newUser.website_email_notification_video_comment" class="custom-control-input">
			<label class="custom-control-label" for="notification-7">{{'newsletter.Box_contributor'|translate}}</label>
		</div>
    <div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.website_email_notification_blog)">
      <input type="checkbox" id="notification-8" [(ngModel)]="newUser.website_email_notification_blog" class="custom-control-input">
      <label class="custom-control-label" for="notification-8">{{'newsletter.Box_blog'|translate}}</label>
    </div>
    <div class="custom-control custom-checkbox" *ngIf="isNotUndefined(newUser.partners_optin)">
      <input type="checkbox" id="notification-5" [(ngModel)]="newUser.partners_optin" class="custom-control-input">
      <label class="custom-control-label" for="notification-5">{{'newsletter.Box_partners'|translate}}</label>
    </div>
		<div class="notifications-button">
			<button (click)="updateProfile()" class="button button-orange">{{'account.Update_your_settings'|translate}}</button>
		</div>
	</div>
</div>
