import {AuthService} from 'app/services/auth.service';
import {Observable, of} from 'rxjs/index';
import {DateMomentService} from 'app/services/date-moment.service';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WebinarService} from 'app/services/webinar.service';
import {Webinar} from 'app/models/webinar.model';
import {TranslationService} from 'app/services/translation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {User} from 'app/models/user.model';
import {LocaleService} from '../../../services/locale.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RateModalComponent} from 'app/components/rate-modal/rate-modal.component';
import {PusherService} from '../../../services/pusher.service';
import {delay, map, tap} from 'rxjs/operators';
import {PollService} from '../../../services/poll.service';
import {RegisterWebinarComponent} from '../../modal/register-webinar/register-webinar.component';
import {UserService} from '../../../services/user.service';
import {CookieService} from 'ngx-cookie-service';
import {WebinarPollComponent} from '../../webinar-poll/webinar-poll.component';
import {JwplayerVideoComponent} from '../../jwplayer-video/jwplayer-video.component';
import {LectureAbstractComponent} from 'app/components/lecture-abstract/lecture-abstract.component';
import {LectureDisclosuresComponent} from '../../lecture-disclosures/lecture-disclosures.component';

declare var gtag;

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss'],
  providers: [
    WebinarService,
    PusherService,
    PollService,
    UserService
  ]
})
export class WebinarComponent implements OnInit, OnDestroy, AfterViewInit {
  webinar$: Observable<Webinar>;
  emailSupport: any;
  slug: string;
  videoLanguage: string;
  registerToken: string;
  registerTokenUrl: string;
  otherParams: any;
  locale: any;
  environment: any = environment;
  currentUser: User;
  webinarRegisterError: boolean;
  webinarRegisterMsg: string;
  channels: string[] = [];
  sessionEnded: boolean = false;
  countDownTime: any;
  webinar_dates: any = [];
  videoAvailableLanguages: any = [];
  showLoading: boolean;
  showPopup: boolean = false;
  chatComments: any[] = [];
  webinarId: number;
  isChrome: boolean;
  user: User;
  logged: any;
  backgroundImage: any = null;
  interval: any;
  showRating: boolean;
  questionToSend: string = '';
  interval2: any;
  @ViewChild(WebinarPollComponent, {static: false}) pollChild: WebinarPollComponent;
  @ViewChild(JwplayerVideoComponent, {static: false}) videoPlayer: JwplayerVideoComponent;
  userRating: number = null;
  currentRating: number = null;
  showPoll: Boolean = false;
  isAop: boolean = environment.domain === 'ophthalmology';
  isMuted: boolean = true;
  teaser_media: any;
  teaserThumbIsShown: boolean = false;
  showAvailableLanguages: boolean = false;

  private viewers: any = [];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private webinarService: WebinarService,
              public dateMomentService: DateMomentService,
              public translationService: TranslationService,
              public authService: AuthService,
              private localeService: LocaleService,
              private pusherService: PusherService,
              private pollService: PollService,
              private userService: UserService,
              private cookieService: CookieService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.logged = false;
    this.videoLanguage = 'en';
    this.authService.isLoggedIn().subscribe(logged => {
      this.logged = logged
    });
    this.isChrome = window.navigator.userAgent.indexOf('Chrome') >= 0;
    this.showLoading = true;
    this.emailSupport = environment.emailSupport;
    this.getUser()
    this.locale = this.localeService.currentLocale();
    this.activatedRoute.params.subscribe(params => {
      this.slug = params['slug'];
      if (params['register'] && params['register'] !== 'undefined') {
        this.registerToken = params['register'];
        this.registerTokenUrl = params['register'];
      } else {
        this.registerToken = '';
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) {
        this.otherParams = JSON.stringify(params);
      }
    });

    this.getWebinar(this.slug);

    this.webinar$.subscribe(r => {
      if (this.environment.production) {
        gtag('event', 'academy_webinar_show_index');
      }
      setTimeout(() => {
        if (typeof r !== 'undefined') {
          this.countDownToBegin(r.datetime_begin);
        }
      }, 1000);
      if (r && r.dates) {
        for (const key of Object.keys(r.dates)) {
          this.webinar_dates.push({
            key: key,
            val: r.dates[key]
          });
        }
      }

      if (r && r.teaser_media) {
        this.teaser_media = r.teaser_media;
      }

      if (r && r.video_available_languages) {
        for (const key of Object.keys(r.video_available_languages)) {
          this.videoAvailableLanguages.push({
            key: key,
            val: r.video_available_languages[key]['name']
          });
          this.videoAvailableLanguages.sort(function compare(a, b) {
            if (a.val < b.val) {
              return -1;
            }
            if (a.val > b.val ) {
              return 1;
            }
            return 0;
          })
        }
      }
      this.showRating = false;
      if (r && r.webinar_launched === 1 && typeof r.webinar_launched !== 'undefined') {
        if (r.minutes_before_start + 30 < 0) {
          // Le webinar a commencé il y a plus de 30 minutes
          this.showRating = true
        } else {
          // temps restant en millisecondes
          const showRating: Observable<boolean> = of(true).pipe(delay((r.minutes_before_start + 30) * 60 * 1000));
          showRating.subscribe(
            showRatingValue => {
              this.showRating = showRatingValue;
            }
          )
        }
      }
      this.userRating = r?.user_has_rated_webinar ? r.user_has_rated_webinar : null
    }, response => {
      if (response.error && response.error.rules && response.error.rules.slug) {
        this.router.navigateByUrl('/not-found')
      }
    });

    this.pusherService.statusStream$
      .subscribe((newStatus) => {
        this.chatStatusChange(newStatus);
      });
    this.pusherService.popupStream$
      .subscribe((launch) => {
        this.togglePopup(launch);
      });
    this.fetchCurrentViewers();
    this.interval = setInterval(() => {
      this.fetchCurrentViewers();
    }, 10000);
    window.setTimeout(function () {
      $('#alert-success').fadeTo(500, 0).slideUp(500, function () {
        $(this).remove();
      });
    }, 4000);
  }

  fetchCurrentViewers() {
    if (this.webinarId !== 0) {
      this.webinarService.getWebinarLiveViewers(this.webinarId).subscribe(
        viewers => {
          this.viewers = viewers;
        });
    }
  }

  toggleSound() {
    this.isMuted = !this.isMuted;
    this.videoPlayer.setMute(this.isMuted);
  }

  showTeaserThumb() {
    this.teaserThumbIsShown = true;
  }

  openLoginPage() {
    this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url}})
  }

  open(content, lecture?) {
    let modalRef;
    if (content === 'abstract') {
      modalRef = this.modalService.open(LectureAbstractComponent);
      modalRef.componentInstance.lecture = lecture;
    } else if (content === 'disclosures') {
      modalRef = this.modalService.open(LectureDisclosuresComponent);
      modalRef.componentInstance.lecture = lecture;
    } else {
      modalRef = this.modalService.open(content);
    }
  }

  openSubscribeWebinarSponsored() {
    const alreadyShow = this.cookieService.get('modal_register_webinar');
    if (!alreadyShow || (alreadyShow && alreadyShow !== 'ok')) {
      const modalRef = this.modalService.open(RegisterWebinarComponent);
      modalRef.componentInstance.title = 'account.Thanks_register_webinar';
      modalRef.componentInstance.subtitle = 'account.Subscribe_academy_web_subtitle';
      modalRef.componentInstance.subtitle2 = 'account.Partners_optin_subtitle';
    }

  }

  getUser() {
    this.currentUser = this.authService.getCurrentUser();
    if (this.currentUser.id) {
      this.userService.getUser(this.currentUser.id, {user_notifications: 1}).subscribe(
        user => {
          this.currentUser = new User({...this.currentUser, ...user});
          if (this.currentUser && this.currentUser.email_notifications &&
            (this.currentUser.email_notifications.subscribe_academy_web === false || this.currentUser.email_notifications.partners_optin === false)) {
            this.authService.setCurrentUser(this.currentUser)
            this.openSubscribeWebinarSponsored();
          }
        })
    }
  }

  changeLanguage(language) {
    this.showLoading = true;
    this.videoLanguage = language;
    this.getWebinar(this.slug);
    this.showAvailableLanguages = !this.showAvailableLanguages;
  }

  getWebinar(slug) {
    if (slug) {
      // get webinar from slug
      this.webinar$ = this.webinarService.getWebinar(slug, {
        company_profiles: 1,
        social_network: 1,
        webinar_comments: 1,
        company_name: 1,
        video_language: this.videoLanguage,
        media_cdn: 1
      }).pipe(
        map((result: any) => result[0]),
        tap(webinar => {
          if (webinar && !this.webinarId) {
            this.prepareComments(webinar.comments);
            this.registerToWebinar(webinar);
            this.channels = ['private-webinar-' + webinar.id + '_' + this.currentUser.id, 'webinar-' + webinar.id];
            this.pusherService.initializePusher(this.channels);
            this.webinarId = webinar.id;
          }
          this.showLoading = false;
        }));
    } else {
      // get next webinar
      this.webinar$ = this.webinarService.getNextWebinar({
        company_profiles: 1,
        social_network: 1,
        webinar_comments: 1,
        webinar_lectures: 1,
        webinar_dates: 1,
        company_name: 1
      })
        .pipe(tap(nextWebinar => {
          this.prepareComments(nextWebinar.comments);
          this.showLoading = false;
          this.registerToWebinar(nextWebinar);
          this.channels = ['private-webinar-' + nextWebinar.id + '_' + this.currentUser.id, 'webinar-' + nextWebinar.id];
          this.pusherService.initializePusher(this.channels);
          this.webinarId = nextWebinar.id;
        }));
    }
  }

  prepareComments(comments) {
    if (comments) {
      this.chatComments = [];
      for (const comment of comments) {
        this.chatComments.push({
          type: 'new',
          id: comment.id,
          comment: comment.content,
          pin: comment.pin,
          user: comment.user_info.fullname,
          slug: comment.user_info.slug,
          user_id: comment.user_id,
          country: comment.user_info.country ? this.translationService.getTranslatedName(comment.user_info.country.translations, this.locale) : null,
          specialty: comment.user_info.specialty ? this.translationService.getTranslatedName(comment.user_info.specialty.translations, this.locale) : null
        });
      }
    }
  }

  registerToWebinar(webinar) {
    if (!this.currentUser || webinar.auth_registered === true) {
      return;
    }
    const register_from = this.otherParams || document.referrer;
    this.webinarService.registerWebinar(webinar.id, this.registerToken ? this.registerToken : '', register_from)
      .subscribe(success => {
        if (success) {
          this.webinarRegisterError = success.error !== 0;
          this.webinarRegisterMsg = success.msg;
          if (this.webinarRegisterError === false && this.registerToken) {
            webinar.auth_registered = true;
          }
          if (!this.registerToken && this.currentUser) {
            this.registerToken = this.currentUser.register_webinar_token;
          }
        }
      });
  }

  scroll(el) {
    el.scrollIntoView();
  }

  chatStatusChange(newStatus) {
    if (newStatus.status === 'launched') {
      location.reload();
    } else if (newStatus.status === 'ended') {
      this.sessionEnded = true;
      location.reload();
    }
  }

  togglePopup(pusherEvt) {
    if (this.currentUser && pusherEvt.launch === 1) {
      const modalRef = this.modalService.open(RateModalComponent);
      modalRef.componentInstance.title = 'webinar.webinar_rating_title';
      modalRef.componentInstance.subtitle = 'webinar.webinar_rating_text';
      modalRef.result.then((result) => {
        this.webinarService.rateWebinar(this.webinarId, result.comment, result.rate).subscribe();
      });
    }
  }

  countDownToBegin(datetime_begin) {
    const now = this.dateMomentService.getNow();
    const begin = this.dateMomentService.getDate(datetime_begin);
    const days = begin.diff(now, 'days');
    const hours = begin.diff(now, 'hours');
    const minutes = begin.diff(now, 'minutes');
    const seconds = begin.diff(now, 'seconds');
    this.countDownTime = {days: days, hours: hours, minutes: minutes, seconds: seconds}

  }

  storeProgress(position, webinarId) {
    if (this.currentUser.id) {
      this.webinarService.addWebinarProgress(webinarId, position).subscribe();
    }
  }

  setCurrentRating(preselection) {
    this.currentRating = preselection;
  }

  rateLiveWebinar() {
    if (this.currentUser && this.userRating == null) {
      const modalRef = this.modalService.open(RateModalComponent);
      modalRef.componentInstance.title = 'webinar.webinar_rating_title';
      modalRef.componentInstance.subtitle = 'webinar.webinar_rating_text';
      modalRef.componentInstance.preselection = this.currentRating;
      modalRef.result.then((result) => {
        this.webinarService.rateWebinar(this.webinarId, result.comment, result.rate).subscribe(
          response => {
            this.userRating = response[0].rating
          }
        );
      });
    }
  }

  sendMessage(event) {
    if (event.keyCode === 13) {
      this.sendQuestion(this.questionToSend);
      this.questionToSend = null;
    }
  }

  private sendQuestion(questionToSend: string) {
    this.pusherService.sendQuestion(this.webinarId, questionToSend);
  }

  setPollStatus(status) {
    this.showPoll = status;
  }

  ngAfterViewInit() {
    this.interval2 = setInterval(() => {
      return this.pollChild ? this.pollChild.fetchCurrentPoll() : true;
    }, 10000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
