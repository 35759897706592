<div class="page">
  <app-section-top [title]="'academy.Alert'" [subtitle]="'alert.Main_subtitle'|translate"
                   [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-alert.jpg'"></app-section-top>
  <div [className]="!environment.mobile ? 'content alert-single' : 'content alert-single has-back-button-padding-top-15'">
    <a  *ngIf="environment.mobile" (click)="historyService.goBack()" class="return-button button button-blue" style="margin-left: 10px; color: white;">
      <i class="fa fa-chevron-left" style="margin-right: 5px"></i>{{'academy.Return'|translate}}
    </a>
    <div class="breadcrumbs mt-2" *ngIf="group">
      <ul>
        <li>
          <a [routerLink]="['/','alert']">{{ 'premium.IMCAS_Alert' | translate}}</a>
        </li>
        <li>
          <a [routerLink]="['/','alert', slug]">{{ translationService.getTranslatedName(group.translations,
            locale) }}</a>
        </li>
        <li>
          <span>{{case?.title}}</span>

        </li>
      </ul>
    </div>
    <app-loader *ngIf="loadingCase"></app-loader>
    <div class="post-main" *ngIf="case && !loadingCase">
      <div class="post-main-title">
        <h1>{{case?.title}}</h1>
        <div *ngIf="canReply() && (group.id == groupCaseId || group.id === groupCaseId2) && isGroupCoordinator()" class="d-flex flex-column align-items-start">
          <p (click)="openInfos()" id="text-collapse" class="mt-2">{{'academy.See_case_information' | translate}} <span><i [className]="open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i></span></p>
          <div #DivCollapse class="collapse">
            <app-loader *ngIf="coordinatorLoading"></app-loader>
            <app-alert-coordinators-form *ngIf="!coordinatorLoading" [caseObj]="case" [group]="group" [groups]="groups" [groupSelect]="groupSelect" [groupCaseId]="groupCaseId"></app-alert-coordinators-form>
          </div>
        </div>

      </div>
      <div class="post post-lead" oncontextmenu="return false;">
        <div class="media post-content">
          <div class="user-info">
            <img class="post-thumb"
                 [src]="case.user?.picture_url ? case.user.picture_url :'https://comexposium-healthcare-assets.s3.eu-west-1.amazonaws.com/users/default/man_doctor.png'"
                 alt="">
            <div class="badges big-screens" *ngIf="case.user && case.user_badges && case.user_badges.length > 0">
              <div class="badge-wrapper" *ngFor="let badge of case.user_badges | slice:0:3;">
                <app-badge
                  [badge]="badge"
                  [size]="1.5"
                  [tooltipMsg]="badge.title"
                ></app-badge>
              </div>
            </div>
            <div class="post-author-mobile">
              <h6 *ngIf="!case.user">{{ 'abstract.Form_author'|translate }}</h6>
              <h6 *ngIf="case.user">{{ case.user.fullname }}</h6>
              <p *ngIf="case.specialty || case.country">
                {{translationService.getTranslatedName(case.specialty?.translations, locale)
              +', '
              + translationService.getTranslatedName(case.country?.translations, locale)}}
              </p>
              <div class="badges" *ngIf="case.user && case.user_badges && case.user_badges.length > 0">
                <div class="badge-wrapper" *ngFor="let badge of case.user_badges | slice:0:3;">
                  <app-badge
                    [badge]="badge"
                    [size]="1.5"
                    [tooltipMsg]="badge.title"
                  ></app-badge>
                </div>
              </div>
            </div>
          </div>
          <div class="media-body">
            <div class="post-author">
              <h6 *ngIf="!case.user">{{ 'abstract.Form_author'|translate }}</h6>
              <h6 *ngIf="case.user">{{ case.user.fullname }}</h6>
              <p *ngIf="case.specialty || case.country">
                {{translationService.getTranslatedName(case.specialty.translations, locale)
              +', '
              + translationService.getTranslatedName(case.country?.translations, locale)}}
              </p>
            </div>
            <div class="post-date text-right">
              <p>{{dateMomentService.getFullDateTime(case.created_at, locale) }}</p>
              <p *ngIf="case.edited_at" class="edit-date">{{ 'alert.Edited' | translate }} {{dateMomentService.getFullDateTime(case.edited_at, locale) }}</p>
            </div>
            <div class="post-desc">
              <h6 *ngIf="case.type != 'discussion'">{{ 'alert.Patient_descr' | translate }}</h6>
              <p *ngIf="case.type != 'discussion'" [innerHTML]="urlify(case.patient_description)"></p>
              <h6 *ngIf="case.type != 'discussion'" >{{ 'alert.Case_descr' | translate }}</h6>
              <p [innerHTML]="case.case_description"></p>
            </div>
            <div *ngIf="!is_group_member()" class="post-information">
              <h4>{{ 'alert.Member_pictures' | translate }}</h4>
              <a *ngIf="group && (group.user_access == 'unauthorized' || group.user_access == 'waiting')" [routerLink]="['/alert', slug ,'join', group.id]"
                 class="button button-orange">
                {{ group && group.user_access === 'waiting' ? ('profile.Request_sent'| translate) :
                ('alert.Btn_no_group'|translate: {
                  name: translationService.getTranslatedName(group ?
                    group.translations : [], locale)
                }) }}
              </a>
            </div>
            <div *ngIf="is_group_member()">
              <div class="post-medias" *ngIf="case?.pictures">
                <a *ngFor="let picture of case?.pictures" class="thumbnail fancybox slide"
                   [attr.rel]="'gallery_'+case.id" [href]="picture.location" [title]="picture.title">
                  <ng-container *ngIf="isPicture(picture)">
                    <img class="img-responsive" [src]="picture.location" [title]="picture.title"
                         alt=""/>
                  </ng-container>
                  <ng-container *ngIf="!isPicture(picture)">
                    <span><i class="fas fa-file"></i> {{picture.title}}</span>
                  </ng-container>
                </a>

              </div>
              <div class="post-medias" *ngIf="case?.videos">
                <video controlsList="nodownload" controls *ngFor="let video of case?.videos"
                       class="img-responsive" [src]="video.url"></video>
              </div>
            </div>

            <div class="post-actions text-right" *ngIf="canAnswerCaseEditMode">
              <a *ngIf="canReply() && !case.user_id" (click)="setFocus()" class="button button-orange">{{
                'alert.Answer_case' | translate }}</a>
              <a *ngIf="case.user_id && case.open" [routerLink]="['/alert/'+slug+'/'+case.id+'/update']" class="button button-orange">{{'alert.edit_alert' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <!--
        CASE COMMENTS
      -->
      <div>
        <app-alert-comment-template *ngFor="let comment of case_wrapups" [comment]="comment" [isWrapup]="true" (replyToComment)="replyTo($event)" [canReply]="canReply()" [currentUser]="currentUser" [case]="{id:case.id, open: case.open}" (needReload)="reloadCase($event)" (canAnswerCaseEditMode)="setRight($event)"></app-alert-comment-template>
        <app-alert-comment-template *ngFor="let comment of case_comments" [comment]="comment" (replyToComment)="replyTo($event)" [canReply]="canReply()" [currentUser]="currentUser" [case]="{id:case.id, open: case.open}" (needReload)="reloadCase($event)" (canAnswerCaseEditMode)="setRight($event)">
            <app-alert-comment-template *ngFor="let subcomment of comment.comments" [comment]="subcomment" (replyToComment)="replyTo($event)" [canReply]="canReply()" [isSubcomment]="true" [currentUser]="currentUser" [case]="{id:case.id, open: case.open}" (needReload)="reloadCase($event)" (canAnswerCaseEditMode)="setRight($event)"></app-alert-comment-template>
        </app-alert-comment-template>

        <!--
          COMMENT FORM
        -->

        <div class="alert alert-danger alert-dismissable" *ngIf="error || (group && group.user_access === 'unauthorized')">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="error = false">×
          </button>
          {{error | translate}}
        </div>
        <div class="post post-add-reply" *ngIf="canReply() && canAnswerCaseEditMode">

          <div class="media post-content">
            <h4>{{ 'alert.Answer_case'|translate }}</h4>
            <div class="user-info">
              <img class="post-thumb" [src]="currentUser.picture_url" alt="">
            </div>
            <div class="media-body">
              <div class="post-author">
                <h6>{{ currentUser.title + ' ' + currentUser.first_name + ' ' + currentUser.last_name
                  }}</h6>
                <p></p>
              </div>
              <div class="post-submit">
                <textarea class="alert-textarea" #focusInput cols="30" rows="10" [(ngModel)]="comment.content" (keydown)="typeComment($event)"
                          [placeholder]="closing ? ('alert.Write_conclusion' | translate) : ('alert.Write_answer'|translate)"></textarea>
              </div>
              <p class="text-secondary" style="font-size: 13px; font-weight: bold;">{{'alert.accepted_format' | translate}} - {{'alert.max_medias' | translate}}</p>
              <ng-container *ngIf="imagesToUpload && imagesToUpload.length > 0 && imagesToUpload !== 'Error'">
                <div class="d-flex">
                  <div class="d-flex flex-column align-items-start">
                  <span class="badge badge-success m-1" *ngFor="let media of imagesToUpload; let index = index;">
                    {{ media.text }}
                    <i class="fa fa-times text-white" style="font-size: 15px" (click)="removeFromList(index)"></i>
                  </span>
                  </div>
                </div>
              </ng-container>
              <span *ngIf="filesAnswerError === 'Error'" class="badge badge-danger">
                {{ ('alert.Type_of_file_error'|translate) + ' : .' + extension + ' Not supported'}}
              </span>
              <span *ngIf="filesAnswerError === 'Max'" class="badge badge-danger">
                         {{'alert.max_files_reached' | translate}} - {{'alert.max_medias' | translate}}
                  </span>
              <span *ngIf="filesAnswerError=== 'Size'" class="badge badge-danger">
                       {{fileName}} {{'alert.max_files_reached' | translate}} - {{'alert.max_files_size' | translate}}
                  </span>
              <span *ngIf="filesAnswerError === 'Back'" class="badge badge-danger my-1">
                    {{'alert.Something_went_wrong' | translate}}
                </span>

              <div class="post-actions">
                <div class="post-actions-files">
                  <label>
                    <input (change)="commentFilesUpload($event)" type="file" [accept]="acceptedFormat"
                           class="custom-file-input" style="display: none;" multiple>
                    <a class="button button-border">{{ 'alert.add_files' | translate }}</a>
                  </label>
                </div>


                <div class="post-actions-send">
                  <button (click)="closing ? closeCase() : submitComment()" *ngIf="!commentSubmitted"
                          class="button {{closing ? 'button-red' : 'button-orange'}}">
                    <span
                      *ngIf="replyToComment && replyToComment.user_info">{{ 'alert.Answer_to' | translate: {name: replyToComment.user_info.title + ' ' + replyToComment.user_info?.last_name} }}</span>
                    <span
                      *ngIf="replyToComment && !replyToComment.user_info">{{'alert.Answer_to'|translate:{name: 'abstract.Form_author'|translate}  }}</span>
                    <span *ngIf="!replyToComment">
                      {{ closing ? ('alert.Close_case' | translate) :
                      ('alert.Answer_case'| translate)}}
                    </span>
                  </button>
                  <button class="button button-orange" disabled="disabled" *ngIf="commentSubmitted">
                    {{'academy.please_wait' | translate}}
                  </button>
                  <a (click)="cancelReplyTo()" *ngIf="replyToComment && !commentSubmitted" class="link">{{
                    'alert.Cancel_reply' | translate }} :
                    <span
                      *ngIf="replyToComment && replyToComment.user_info">{{ replyToComment.user_info.title + ' ' + replyToComment.user_info?.last_name }}</span>
                    <span *ngIf="!replyToComment.user_info">{{'abstract.Form_author'|translate}}</span>
                  </a>
                </div>
              </div>

              <div class="custom-control custom-checkbox" *ngIf="!replyToComment && (isAuthor() || isGroupCoordinator())">

                <input type="checkbox" id="case-close" name="case-close"
                       class="custom-control-input"
                       [checked]="closing" (change)="closing = !closing">
                <label class="custom-control-label" for="case-close">
                  {{ 'alert.Label_close_case'|translate }}
                </label>
              </div>

              <div class="custom-control custom-checkbox">
                <input type="checkbox" id="notified-me" name="notified-me"
                       class="custom-control-input" (click)="followCase($event)"
                       [ngModel]="case.subscriber">
                <label class="custom-control-label" for="notified-me">{{
                  'alert.Label_subscribe_case'|translate }}</label>
              </div>
              <div class="custom-control custom-checkbox" *ngIf="case.can_choose_anonymity">
                <input type="checkbox" id="anonymous" class="custom-control-input"
                       [checked]="anonymous" (change)="anonymous = !anonymous">
                <label class="custom-control-label" for="anonymous">{{ 'alert.Answer_anon'|translate
                  }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="post-information" *ngIf="!case.open && group">
          <h4>{{ 'alert.Case_closed'|translate }}</h4>
          <button *ngIf="isAuthor() || isGroupCoordinator()"
                  class="button button-red" (click)="openCase()" style="margin-right:10px">
            {{'alert.Reopen'|translate}}
          </button>
          <button class="button button-orange" [routerLink]="['/alert', slug ,'submit', group.id]">{{
            'alert.Submit_case'|translate }}
          </button>

        </div>
        <div class="post-information"
             *ngIf="group !== undefined && case && !is_group_member() && is_physician()">
          <h4>{{ 'alert.Message_no_group'|translate }}</h4>
          <div class="row imageset" *ngIf="case.advisors">
            <div class="col-md-2 col-sm-3 col-4" *ngFor="let picture of case.advisors">
              <div [style.background]="'url(' + picture + ')'" [style.background-size]="'cover'"
                   class="rounded-circle"></div>
            </div>
          </div>

          <br/>
          <a *ngIf="group && (group.user_access === 'unauthorized' || group.user_access == 'waiting')" [routerLink]="['/alert', slug ,'join', group.id]"
             class="button button-orange">
            {{ group && group.user_access === 'waiting' ? ('profile.Request_sent'| translate) :
            ('alert.Btn_no_group'|translate: {
              name: translationService.getTranslatedName(group ?
                group.translations : [], locale)
            }) }}
          </a>

        </div>
        <div class="post-information" *ngIf="group && case && !is_group_member() && !is_physician()">
          <h4>{{ 'alert.Error_not_doctor'| translate }}</h4>
        </div>
        <div class="post" *ngIf="group && videos_related && videos_related.length > 0">
          <h4>{{ 'academy.Related_videos'|translate }}</h4>
          <div class="row">
            <div class="col-4 container-lectures" *ngFor="let mItem of videos_related">
              <app-lecture [lecture]="mItem" [card]="true" [thumbFormat]="'rectangular'"></app-lecture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
