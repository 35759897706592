import {Component, OnInit} from '@angular/core';
import {PaymentService} from '../../../../services/payment.service';
import {Payment} from '../../../../models/payment.model';
import {DateMomentService} from '../../../../services/date-moment.service';
import {LocaleService} from '../../../../services/locale.service';
import {environment} from '../../../../../environments/environment';
import {CartService} from '../../../../services/cart.service';

declare var gtag;

@Component({
  selector: 'app-thanks-bank-transfert',
  templateUrl: './thanks-bank-transfert.component.html',
  styleUrls: ['./thanks-bank-transfert.component.scss'],
  providers: [PaymentService, CartService]
})
export class ThanksBankTransfertComponent implements OnInit {
  payment: Payment;
  locale: string;
  environment: any = environment;

  constructor(private paymentService: PaymentService,
              private cartService: CartService,
              public dateMomentService: DateMomentService,
              public localeService: LocaleService) {
  }

  ngOnInit() {
    this.cartService.deleteCurrentCartId();
    this.locale = this.localeService.currentLocale();
    this.paymentService.getLast().subscribe(last => {
      this.payment = new Payment(last);
    });
    gtag('event', 'academy_subscription_step3_payment_thanks');
  }

  getPrice() {
    let price = 0;
    this.payment.prices.forEach(element => {
      if (element.currency === this.payment.currency) {
        price = element.grand_total;
      }
    });
    return price;
  }
}
