import {environment} from '../../../../environments/environment';
import {Component, OnInit} from '@angular/core';
import {ArticleService} from 'app/services/article.service';
import {ArticleModel} from 'app/models/article.model';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationService} from 'app/services/translation.service';
import {DateMomentService} from 'app/services/date-moment.service';
import {User} from 'app/models/user.model';
import {UserService} from 'app/services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {CommentService} from 'app/services/comment.service';
import {MediaService} from 'app/services/media.service';
import {LocaleService} from '../../../services/locale.service';
import {AuthService} from '../../../services/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
import {LocalStorage} from '@ngx-pwa/local-storage';

declare var gtag;


@Component({
    selector: 'app-blog-article',
    templateUrl: './blog-article.component.html',
    styleUrls: ['./blog-article.component.scss'],
    providers: [ArticleService, UserService, CommentService, MediaService]
})
export class BlogArticleComponent implements OnInit {
    user: User;
    locale: string;
    articleId: number;
    article: ArticleModel;
    relatedArticles: ArticleModel[];
    comment: string;
    modal: any;
    FB: any;
    twitter: any;
    loadingArticle: boolean;
    environment: any = environment;

    dataReceivedFromWeb = [];

    constructor(private activatedRoute: ActivatedRoute,
                private articleService: ArticleService,
                private router: Router,
                public translationService: TranslationService,
                private translate: TranslateService,
                public dateMomentService: DateMomentService,
                private userService: UserService,
                private commentService: CommentService,
                private localeService: LocaleService,
                private modalService: NgbModal,
                private authService: AuthService,
                public mediaService: MediaService,
                private sanitizer: DomSanitizer,
               protected localStorage: LocalStorage) {
    }

    pdfUrl(url) {
        const fullUrl = 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + url;
        return this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
    }

    ngOnInit() {
        this.loadingArticle = true;
        this.locale = this.localeService.currentLocale();
        this.activatedRoute.params.subscribe(params => {
            this.articleId = params['id'];
            this.getArticle(this.articleId);
        });
        this.getUser();
    }


    getUser() {
        this.user = this.authService.getCurrentUser();
        if (this.user.id) {
            this.userService.getUser(this.user.id).subscribe(
                user => {
                    this.user = new User({...this.user, ...user});
                }
            )
        }
    }

    getArticle(articleId) {
        this.localStorage.getItem < any > ('BLOG-article_'+articleId+'_' + environment.domain).subscribe((data) => {
          if (!(('article_'+articleId) in this.dataReceivedFromWeb) && data) {
            this.setDataArticle(data, false);
          }
        });

        this.articleService.getArticle(articleId, {
            user_specialty: 1,
            user_country: 1,
            connections: 1,
            disclosures: 1,
            user_biostatement: 1,
            media_speakers: 0,
            media_cdn: 0,
            media_lecture: 0,
            academy_media_course: 0,
            article_academy_medias: 1,
            article_min: 1,
            article_content: 1,
            article_related: 1,
            article_comments: 1,
            public: 1
        }).subscribe(
            article => {
                this.setDataArticle(article, true);
                this.dataReceivedFromWeb['article_'+articleId] = true;
                this.localStorage.setItem('BLOG-article_'+articleId+'_' + environment.domain, article).subscribe(() => {});
            }
        );
    }

    setDataArticle(article, fromNetwork){
      this.loadingArticle = false;
      this.article = article;
      this.transformContent(this.article);
      if (fromNetwork){
        this.getRelatedArticles(this.article);
        setTimeout(() => {
            if (typeof FB !== 'undefined') {
                FB.XFBML.parse();
            }
        }, 500);
      }

    }

    getRelatedArticles(article) {
        const ids = article.related.slice(0, 2).join(',');
        if (ids) {
            this.articleService.getArticles(null, {
                user_specialty: 0,
                connections: 0,
                disclosures: 0,
                user_biostatement: 0,
                media_speakers: 0,
                media_cdn: 0,
                media_lecture: 0,
                academy_media_course: 0,
                ids: ids,
                article_related_academy_medias: 0,
                article_academy_medias: 0,
                article_min: 1,
                article_content: 0,
                article_related: 0,
                article_comments: 0
            }).subscribe(
                results => {
                    this.relatedArticles = results;
                }
            );
        }

    }

    connect(author) {
        if (this.user.id) {
            this.userService.connect(author.id).subscribe(
                success => {
                    gtag('event', 'click-to-connect-author');
                    this.article.authors[0].friendship_status_with_auth = 'waiting_friend_accept';
                }
            )
        } else {
            location.href = '/academy/login';
        }
    }

    open(content) {
        this.modal = this.modalService.open(content);
    }

    transformContent(article) {
        if (article.content) {
            this.translate.get('blog.Are_you_interested').subscribe((videoText: string) => {
                article.content = article.content
                // paragraph
                    .replace(/\[paragraph\]/gi, '<p>')
                    .replace(/\[\/paragraph\]/gi, '</p>')
                    // subtitle
                    .replace(/\[subtitle\]/gi, '<p><span class=\"subtitle\">')
                    .replace(/\[\/subtitle\]/gi, '</span></p>')
                    // quote
                    .replace(/\[quote\]/gi, '<div class=\"blockquote\"><span class=\"quote-icon\">“</span><span class=\"quote\">')
                    .replace(/\[\/quote\]/gi, '</span></div>')
                    // quote author
                    .replace(/\[quote-author\]/gi, '<div class=\"quote-author\">')
                    .replace(/\[\/quote-author\]/gi, '</div>')
                    // video
                    .replace(/\[video\]/gi, article.main_academy_media ?
                        '<div class=\"video\"><div class=\"row\"><div class=\"col-md-5 col-12 video-desc\"><h5>' +
                        this.translationService.getTranslatedAttribute('title', article.main_academy_media.media.translations, this.locale) +
                        '</h5><p>' + videoText + '</p></div><div class=\"col-md-7 col-12\"><div class=\"video-wrapper\"><div class=\"video-badges\"><span class=\"board\">Premium</span></div><a href=\"' + '/academy/show/' + article.main_academy_media.id + '/' + this.translationService.getSlugFromTranslations('title', article.main_academy_media.media.translations) + '\"><div class="video-play"><i class="fa fa-play big-play"></i></div><img src=\"' + article.main_academy_media.media.thumb + '\"></a></div></div></div></div>' : '');
            })
        }
    }
}
