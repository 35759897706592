import {Media} from './media.model';
import {Course} from 'app/models/course.model';
import {ScientificSociety} from 'app/models/scientific-society.model';
import {HumanitarianOrganization} from 'app/models/humanitarian-organization.model';
import {environment} from '../../environments/environment';
import {CompanyProfileModel} from './company-profile.model';
import {Theme} from './theme.model';

interface SocialNetwork {
  website: string;
  twitter: string;
  facebook: string;
  instagram: string;
}

export class User {
  id: number;
  first_name: string;
  last_name: string;
  address: string;
  dob: string;
  phone: string;
  email: string;
  user_specialty_id: number;
  country_id: number;
  password: string;
  discountCode: string;
  subscribe_academy_web: boolean | number;
  subscribe_web: boolean | number;
  subscribe_partners: boolean | number;
  company: boolean | number;
  website_email_notification_message: boolean | number;
  website_email_notification_connection_request: boolean | number;
  partners_optin: boolean | number;
  website_email_notification_alert: boolean | number;
  website_email_notification_video_comment: boolean | number;
  website_email_notification_blog: boolean | number;
  medias: Media[];
  media_viewed: any;
  courses: Course[];
  courses_in_progress: Course[];
  courses_finished: any[];
  courses_bought: any[];
  company_profiles: CompanyProfileModel[];
  specialty: any;
  country: any;
  register_webinar_token: string;
  cover_url: string | null;
  logo_url: string | null;
  cover_gallery: number | null;
  title: string;
  premium_membership: boolean;
  theme_accesses: any[];
  picture_url: string;
  comments_allowed: number | string;
  social_network: SocialNetwork;
  specialty_category: string;
  gender: string;
  postal_code: string;
  company_name: string;
  city: string;
  biostatement: string;
  pubmed_references: any[];
  scientific_societies: ScientificSociety[];
  humanitarian_organizations: HumanitarianOrganization[];
  connections: any;
  media_ids: any;
  email_notifications: any;
  badge: any;
  next_lectures: any;
  themes: any;
  favorited: any;
  slug: string;
  name: string;
  keywords: any;
  friendship_status_with_auth: string;
  friends: number[];
  private env = environment;
  thread_with_auth: number;
  fullname: string;
  twitter_username: string;
  facebook_url: string;
  instagram_username: string;
  diploma_status: string;
  specialty_verified: boolean;
  personal_token: string;
  badges: any;
  medical_resident: boolean;
  language: string;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.first_name = obj && obj.first_name || '';
    this.last_name = obj && obj.last_name || '';
    this.dob = obj && obj.dob || '';
    this.address = obj && obj.address;
    this.social_network = obj && obj.social_network;
    this.email = obj && obj.email || '';
    this.password = obj && obj.password || '';
    this.phone = obj && obj.phone || '';
    this.user_specialty_id = obj && obj.user_specialty_id || 1;
    this.discountCode = obj && obj.discountCode || '';
    this.subscribe_academy_web = obj && obj.subscribe_academy_web;
    this.subscribe_web = obj && obj.subscribe_web || true;
    this.website_email_notification_message = obj && obj.website_email_notification_message || true;
    this.website_email_notification_connection_request = obj && obj.website_email_notification_connection_request || true;
    this.partners_optin = obj && obj.partners_optin || true;
    this.website_email_notification_alert = obj && obj.website_email_notification_alert || true;
    this.website_email_notification_video_comment = obj && obj.website_email_notification_video_comment || true;
    this.website_email_notification_blog = obj && obj.website_email_notification_blog || true;
    this.medias = obj && obj.medias;
    this.media_viewed = obj && obj.media_viewed;
    this.courses = obj && obj.courses;
    this.company = obj && obj.company;
    this.courses_in_progress = obj && obj.courses_in_progress;
    this.courses_finished = obj && obj.courses_finished;
    this.courses_bought = obj && obj.courses_bought;
    this.specialty = obj && obj.specialty;
    this.country = obj && obj.country;
    this.country_id = this.country ? this.country.id : obj && obj.country_id || 1;
    this.register_webinar_token = obj && obj.register_webinar_token;
    this.title = obj && obj.title;
    this.premium_membership = obj && obj.premium_membership;
    this.theme_accesses = obj && obj.theme_accesses;
    this.picture_url = obj && obj.picture_url;
    this.comments_allowed = obj && obj.comments_allowed;
    this.specialty_category = obj && obj.specialty_category;
    this.gender = obj && obj.gender;
    this.postal_code = obj && obj.postal_code;
    this.company_name = obj && obj.company_name;
    this.city = obj && obj.city;
    this.biostatement = obj && obj.biostatement;
    this.pubmed_references = obj && obj.pubmed_references;
    this.scientific_societies = obj && obj.scientific_societies;
    this.humanitarian_organizations = obj && obj.humanitarian_organizations;
    this.connections = obj && obj.connections || [];
    this.media_ids = obj && obj.media_ids;
    this.cover_url = obj && obj.cover_url;
    this.logo_url = obj && obj.logo_url;
    this.cover_gallery = obj && obj.cover_gallery;
    this.email_notifications = obj && obj.email_notifications;
    this.badge = obj && obj.badge;
    this.next_lectures = obj && obj.next_lectures;
    this.language = obj && obj.language;
    if (obj && obj.themes) {
      this.themes = [];
      obj.themes.forEach(element => {
        this.themes.push(new Theme(element));
      });
    }
    this.slug = obj && obj.slug;
    this.favorited = obj && obj.favorited;
    this.name = obj && obj.name;
    this.keywords = obj && obj.keywords;
    this.friendship_status_with_auth = obj && obj.friendship_status_with_auth;
    this.friends = obj && obj.friends;
    if (obj && obj.company_profiles) {
      this.company_profiles = [];
      obj.company_profiles.forEach(element => {
        this.company_profiles.push(new CompanyProfileModel(element));
      });
    }
    this.thread_with_auth = obj && obj.thread_with_auth;
    this.fullname = obj && obj.fullname;
    this.twitter_username = obj && obj.twitter_username;
    this.facebook_url = obj && obj.facebook_url;
    this.instagram_username = obj && obj.instagram_username;
    this.diploma_status = obj && obj.diploma_status;
    this.specialty_verified = obj && obj.specialty_verified;
    this.personal_token = obj && obj.personal_token;
    this.badges = obj && obj.badges;
    this.medical_resident = obj && obj.medical_resident;
  }

  getCompanyProfile() {
    if (this.company_profiles && typeof this.company_profiles[0] !== 'undefined') {
      return this.company_profiles[0];
    }
    return null;
  }

  getProfileUrl() {
    return '/profile/' + this.slug;
  }

  removeFavoriteAcademyMedia(academyMediaId) {
    delete this.favorited.academy_medias[academyMediaId];
  }

  addFavoriteAcademyMedia(academyMediaId) {
    this.favorited.academy_medias.push(academyMediaId);
  }

  getFullName() {
    if (this.name) {
      return this.name;
    } else {
      return this.first_name + ' ' + this.last_name;
    }
  }
}
