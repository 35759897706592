<div class="modal-body modal-no-title">
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <h3>{{ 'academy.Subscription_modal_title_ending'|translate }}</h3>
    <p [innerHTML]="'academy.Subscription_modal_description_ending'|translate: {link: 'account/current-plan'} "></p>
    <div class="text-center">
        <a [routerLink]="['/premium']" [queryParams]="{'auto-subscribe':''}"
           class="btn btn-full orange">{{ 'account.Extend_subscription'|translate }}</a> <span
            class="btn btn-default" (click)="close()">{{ 'account.Hide'|translate }}</span>
    </div>
</div>
