import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CongressService} from 'app/services/congress.service';
import {UserService} from 'app/services/user.service';
import {User} from 'app/models/user.model';
import {Observable} from 'rxjs';
import {MediaService} from 'app/services/media.service';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {CongressProduct} from '../../../models/congress-product.model';
import {CartService} from '../../../services/cart.service';
import {AcademySubscriptionService} from '../../../services/academy-subscription.service';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from '../../../services/theme.service';
import {Theme} from '../../../models/theme.model';
import {TranslationService} from '../../../services/translation.service';
import {LocaleService} from '../../../services/locale.service';
import {ConfirmationComponent} from "../../modal/confirmation/confirmation.component";

declare var gtag;


@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss'],
  providers: [CongressService, UserService, MediaService, CartService, AcademySubscriptionService, ThemeService]
})
export class PremiumComponent implements OnInit {
  user: User;
  products: Array<CongressProduct>;
  mediasCount$: Observable<any>;
  environment: any = environment;
  locale: string;
  themes: Array<Theme>;
  isAop: boolean = environment.domain === 'ophthalmology';
  requestError: string = "";
  constructor(
    private modalService: NgbModal,
    private congressService: CongressService,
    private authService: AuthService,
    private userService: UserService,
    private cartService: CartService,
    private academySubscriptionService: AcademySubscriptionService,
    private mediaService: MediaService,
    private themeService: ThemeService,
    private translateService: TranslateService,
    private localeService: LocaleService,
    public translationService: TranslationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.getProducts();
    this.getMediasCount();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.congressProductId && params.type) {
        this.openCart(params.congressProductId, params.type);
      }
    });
    if (this.environment.domain === 'aesthetics') {
      this.locale = this.localeService.currentLocale();

      this.themes = [];
      this.themeService.getThemes({ids: '34,40,42,44,46,50,56'}).subscribe(res => {
        res.forEach(m => {
          this.themes.push(new Theme(m));
        });
      });
    }
  }

  open(content) {
    this.modalService.open(content, {windowClass: 'modal-wide'});
  }

  getProducts() {
    this.products = [];
    this.user = this.authService.getCurrentUser();
    this.congressService.getCongressProducts({all: 1}).subscribe(data => {
      this.getUser();
      data.forEach(m => {
        this.products.push(new CongressProduct(m));
      });

    });
  }

  getMediasCount() {
    this.mediasCount$ = this.mediaService.getAcademyMediasCount();
  }

  checkCartId() {
    if (!this.cartService.getCurrentCartId()) {
      this.cartService.getCartId('academy_cart_id').subscribe(cartId => {
        this.cartService.setCurrentCartId(cartId);
      });
    }
  }

  getUser() {
    if (this.user.id) {
      this.userService.getUser(this.user.id).subscribe(
        user => {
          gtag('event', 'academy_subscription_step1_subscribe_page');
          this.user = new User({...this.user, ...user});
          this.checkCartId();
          if (window.location.href.endsWith('auto-subscribe=')) {
            let congressProduct;
            if (this.user && this.user.specialty_category === 'physician') {
              congressProduct = this.products[0].id;
            } else if (this.user && this.user.specialty_category !== 'physician') {
              congressProduct = this.products[1].id;
            }
            this.openCart(congressProduct, 'personal');
          }
        }
      );
    } else {
      gtag('event', 'academy_subscription_step1_subscribe_page_not_logged');
    }
  }

  openCart(congressProductId, type) {
    if (this.user && this.user.id) {
      if (!this.cartService.getCurrentCartId() || this.cartService.getCurrentCartId() === 'null') {
        this.cartService.getCartId('academy_cart_id').subscribe(cartId => {
          this.onlyOneProductCheck(cartId, congressProductId, type);
        }, error => {
          this.cartService.deleteCurrentCartId();
        });
      } else {
        this.onlyOneProductCheck(this.cartService.getCurrentCartId(), congressProductId, type);
      }
    } else {
      this.router.navigate(['/account/login'], {
        queryParams: {
          returnUrl: this.router.url + '?congressProductId=' + congressProductId + '&type=' + type
        }
      })
    }

  }
  getSubscription(congressProductId, type, cartId) {
    this.academySubscriptionService.post({
      congress_product_id: congressProductId,
      type: type,
      cart_id: cartId
    }).subscribe(subscriptionId => {
      this.router.navigateByUrl('/cart/subscribe', {state: {type: type}});
    }, error => {
      this.cartService.deleteCurrentCartId();
      this.checkCartId();
      setTimeout(() => {
        this.openCart(congressProductId, type);
      }, 500)

    })
  }

  subscribeTooltip() {
    if (this.user && this.user.id && this.user.specialty_category !== 'physician') {
      return this.translateService.instant('premium.Not_physician');
    }
    return '';
  }

  private onlyOneProductCheck(cartId: any, congressProductId: any, type: any) {
    this.cartService.hasProduct(cartId).subscribe(hasProduct => {
      if (hasProduct[0]) {
        const modalRef = this.modalService.open(ConfirmationComponent);
        modalRef.componentInstance.title = 'You already have a product in your cart. Would you like to exchange it for this one ?'; //marketplace.Cart_no_empty
        modalRef.result.then(() => {
          this.cartService.clean(cartId).subscribe(() => {
            this.cartService.getCartId('academy_cart_id').subscribe(newCartId => {
              this.cartService.setCurrentCartId(newCartId);
              this.getSubscription(congressProductId, type, newCartId);
            }, () => {this.requestError = "Something went wrong, please retry later"});
          }, () => {this.requestError = "Something went wrong, please retry later"})
        }).catch(() => {this.requestError = "This product has not been added"}); //marketplace.Product_no_added
      } else {
        this.cartService.setCurrentCartId(cartId);
        this.getSubscription(congressProductId, type, cartId);
      }
    }, error => {
      if (error.status === 404) {
        this.cartService.deleteCurrentCartId();
        this.openCart(congressProductId, type);
      } else {
          this.requestError = "Something went wrong, please retry later";
        }
      })

  }
}
