import {ConfirmationComponent} from '../modal/confirmation/confirmation.component';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LocaleService} from 'app/services/locale.service';
import {AuthService} from 'app/services/auth.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Locales} from '../../locales';
import {environment} from '../../../environments/environment';
import {User} from 'app/models/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location, PopStateEvent} from '@angular/common';
import {WebsiteLaunchingEmailService} from '../../services/website-launching-email.service';
import {UserService} from '../../services/user.service';
import {NetworkService} from "../../services/mobile-services/network.service";
import {PushNotificationsService} from "../../services/mobile-services/push-notifications.service";

declare var gtag;

@Component({
  selector: 'pseudo-root',
  templateUrl: './locale.component.html',
  styleUrls: ['./locale.component.scss'],
  providers: [
    LocaleService,
    WebsiteLaunchingEmailService,
    PushNotificationsService
  ]
})
export class LocaleComponent implements OnInit, AfterViewInit {
  headerMenuOpened = false;
  languageOptions = Locales;
  selectedLanguage: any;
  modalOpened: boolean;
  logoutOpened: boolean;
  phonenumber: any;
  emailSupport: any;
  environment: any;
  subscribed: boolean;
  consent: boolean;
  missingConsent: boolean;
  email: string;
  user: User;
  logo: string;
  year: number = new Date().getFullYear();
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  showAlert: boolean = false;
  logged: any;


  constructor(private activatedRoute: ActivatedRoute,
              private localeService: LocaleService,
              private authService: AuthService,
              private websiteLaunchingEmailService: WebsiteLaunchingEmailService,
              private translate: TranslateService,
              public router: Router,
              private modalService: NgbModal,
              private location: Location,
              private userService: UserService,
              public networkService: NetworkService,
              private pushNotificationsService: PushNotificationsService) {
    this.environment = environment;
    if (this.environment.domain === 'ophthalmology') {
      translate.setDefaultLang('fr');
    } else {
      translate.setDefaultLang('en');
    }
    this.modalOpened = false;
    this.logoutOpened = false;
    this.logo = this.environment.domain === 'ophthalmology' ?
      this.environment.base_url + '/img/academy/layout/logo_aop.png' :
      this.environment.base_url + '/img/academy/layout/logo.png';
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          'page_location': document.location.origin + event.urlAfterRedirects,
          'page_title': document.title
        });
      }
    });
  }

  getLanguagesAvailable() {
    if (this.environment.domain !== 'aesthetics') {
      delete this.languageOptions.es;
      delete this.languageOptions.ru;
      delete this.languageOptions.zh;
    }
    return this.languageOptions;
  }

  ngOnInit() {
    this.logged = false;
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (ev instanceof NavigationEnd) {
        if (ev.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this.phonenumber = environment.phonenumber;
    this.emailSupport = environment.emailSupport;

    this.localeService.validateLocale(this.localeService.currentLocale());

    this.selectedLanguage = this.translate.currentLang;
    const localUser = this.authService.getCurrentUser();
    this.setShowAlert();
    this.authService.isLoggedIn().subscribe(loggedIn => {
      this.user = this.authService.getCurrentUser();
      this.logged = loggedIn;
      if (loggedIn && environment.mobile) {
        this.pushNotificationsService.instanciatePushNotifications();
      }
      this.setShowAlert();
    });
    this.consent = false;
    this.missingConsent = false;
    if (localUser.email) {
      this.email = localUser.email;
      this.user = localUser;
    }
    this.subscribed = false;
  }

  languageChanged(data) {
    this.selectedLanguage = data;
    if (this.user) {
      this.selectedLanguage = data;
      this.userService.updateUser({
        language: this.selectedLanguage
      }).subscribe(user => {
        window.location.href = this.environment.base_url + '/' + this.selectedLanguage + '/academy' + this.router.url;
      });
    } else {
      const pathWithoutLocale = this.router.url.replace(this.translate.currentLang + '/', '');
      window.location.href = this.environment.base_url + '/' + data + '/academy' + pathWithoutLocale;
    }
  }

  openLogoutConfirmation() {
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.title = 'account.Do_you_want_to_logout';
    modalRef.result.then(result => {
      this.authService.logout();
    }, result => {
    });
  }


  subscribeToBlog() {
    if (!this.consent) {
      this.missingConsent = true;
      return false;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(this.email).toLowerCase())) {
      this.websiteLaunchingEmailService.subscribeToBlog(this.email).subscribe((r) => {
        this.subscribed = true;
      });
    }

  }

  showBlogSubscribeButton() {
    return !this.subscribed;
  }

  setShowAlert() {
    this.showAlert = !this.logged ||
      (this.user && this.user.specialty_category === 'others' || this.user.specialty_category === 'physician');
  }
}
